@use "sass:color";
@use "vars" as *;
@use "fonts" as *;
@use "animations" as *;
@use "transitions" as *;
@use "defaults";

html {
    font-size: 16px;
}

body {
    font-weight: 300;
}

h1 {
    font-size: 2.7rem;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 2rem;
}

h4 {
    font-size: 1.7rem;
}

h5 {
    font-size: 1.5rem;
}

.pointer {
    cursor: pointer;
}

.slideDown {
    @include slide-in-animation(-25px);
}

.slideUp {
    @include slide-in-animation(25px);
}

.slideInLeft {
    @include slide-in-animation(-25px, "horizontal");
}

.slideInRight {
    @include slide-in-animation(25px, "horizontal");
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.disabledEvents {
    opacity: .2;
    pointer-events: none !important;

    input,
    textarea,
    button {
        pointer-events: none !important;
    }
}


::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: #6a6a6a;
    border-radius: 6px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.disableSelect {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}