@use "src/styles/vars" as *;

.footer {
  width: 100%;
  margin-top: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &>div {
    font-size: 0.9rem;
    margin: 3rem auto;
    max-width: $max-page-width;
  }
}