@use "sass:color";

.container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.switch {
  $width: 40px;
  $height: 25px;
  $slider-length: $height - 6;

  position: relative;
  display: inline-block;
  width: $width;
  height: $height;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked+.slider:before {
      transform: translateX($width - $slider-length - 4);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: $height;
    transition: all 0.25s;

    &:before {
      position: absolute;
      content: "";
      height: $slider-length;
      width: $slider-length;
      left: 2px;
      top: 3px;
      border-radius: 50%;
      transform: translateX(0);
      transition: all 0.25s;
    }
  }
}