@use "sass:color";
@use "src/styles/vars" as *;

.body {
  padding: 1rem 2rem 5rem 2rem;
  overflow-x: auto;

  &>div {
    margin: 0px auto;
    max-width: $max-page-width;
  }
}