.fileWrapper {

    flex-wrap: wrap;
    display: flex;
    flex-direction: row;

    .fileParent {
        padding: 6px;
        margin: 0px 10px 10px 10px;
        border: 1px solid transparent;
    }
}