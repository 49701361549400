.eventContainer {
    padding: 10px 20px;
    border-radius: 12px;

    .invisibleToggle,
    .visibleToggle {
        visibility: hidden;
    }

    &:hover {
        .visibleToggle {
            visibility: visible;
        }
    }
}