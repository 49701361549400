@use "src/styles/animations" as *;

.title {
  @include slide-in-animation(-25px);
}

.box {
  padding: 1.7rem;
  border-radius: 4px;
  min-width: 200px;
  min-height: 140px;
  font-size: 1rem;
  letter-spacing: 2px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: 0.3s all;

  @include slide-in-animation(25px);

  .desc {
    font-size: 0.7rem;
    margin-top: 0.5rem;
    max-width: 190px;
    text-align: center;
    font-weight: 300;
  }

}