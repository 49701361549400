.busy {
    opacity: .9;
    pointer-events: none !important;
    cursor: not-allowed;

    input,
    textarea,
    button {
        pointer-events: none !important;
    }
}