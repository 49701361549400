.pgWrapper {
    gap: 3px;
    display: flex;
    flex-direction: row;

    .pgDot {
        width: 5px;
        height: 5px;
        border-radius: 15px;

        &.active {
            width: 25px;
        }
    }
}