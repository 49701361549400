.mcWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    flex-wrap: wrap;

    .details {
        flex: 1;
        padding: 20px;
    }

    &.forceColumn,
    &.grid {
        align-items: normal;
        flex-direction: column;

        .details {
            width: 100%;
            padding: 20px 10px;
        }
    }

    &.forceColumn {
        width: 100%;
        margin-bottom: 10px;
    }
}