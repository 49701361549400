.group {
  display: flex;
  gap: 2rem;
  max-width: 100%;

  &.none {
    gap: 0rem;
  }

  &.xs {
    gap: 0.3rem;
  }

  &.sm {
    gap: 1rem;
  }

  &.smm {
    gap: 1.5rem;
  }

  &.md {
    gap: 2rem;
  }

  &.lg {
    gap: 3rem;
  }

  &.xl {
    gap: 4rem;
  }
}