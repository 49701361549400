.container {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  margin-top: 1rem;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}