@use "sass:color";

.input {
  height: 2.2rem;
  padding: 0rem 0.5rem;
  border-radius: 3px;

  &.hasrighticon {
    padding-right: 32px;
  }

  &.sm {
    padding: 0rem 0.5rem;
    font-size: 0.9rem;
    height: 1.88rem;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
}